/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 90px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: rgba(42, 44, 57, 0.9);
  position: fixed;
  width: 100%;
  margin-top: -90px;
}
#header + div.forMenu {
  margin-top: 90px;
}
#header.header-transparent {
  background: #ffffff;
  padding-top: 12px;
  padding-right: 20px;
}

#header.header-scrolled {
  background: rgb(247 245 255);
  z-index: 999990;
  height: auto;
}

#header .logo h1 {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #fff;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 0;
  width: 146px;
}
#header .search-btn-value {
  border: 2px solid #000000;
  border-radius: 0;
  width: 100%;
  padding-right: 26px;
  font-size: 13px;
}
.serch-form {
  position: relative;
  max-width: 235px;
}
.serch-form button.search-form {
  position: absolute;
  right: 9px;
  top: 5px;
  background: transparent;
  border: none;
}
.side-text-bar i {
  color: #f13030;
}
.side-text-bar a {
  color: #2b2b2b;
}
.side-text-bar button {
  padding: 12px 27px 11px;
  font-size: 16px;
  background-color: #f13030;
  color: #ffffff;
  font-size: 16px;
  border: 1px solid #f13030;
}
/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
