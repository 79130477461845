a {
  text-decoration: none !important;
}

/* Section First Banner */
.banner-section-home {
  /* background-image: url("../images/background.png"); */
  background-size: cover;
  min-height: 100vh;
  margin-top: -100px;
  padding-top: 200px;
}
.box-heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 4.5rem;
  color: #000000;
  margin: 0;
  margin-bottom: 45px;
}
.red-box {
  max-width: 423px;
  width: 100%;
  background: #f13030;
  border: 1px solid #ffffff;
  border-radius: 30px;
  padding: 34px;
  margin-left: auto;
}
input.input-btn-value {
  max-width: 355px;
  width: 100%;
  margin-bottom: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #9b9b9b;
}
.appoint-text {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  color: #ffffff;
}
.more-btn {
  margin-top: 50px;
}
.banner-section-home p.para {
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
}

/* About Us Section */
.about-us-section {
  padding-top: 50px;
  padding-bottom: 100px;
}
.side-box {
  padding-left: 40px;
}
.about-us {
}
.para-text {
}
.para-tex-value {
  margin: 0;
  padding-top: 45px;
}
#main .know-more-btn {
  margin: 0;
  margin-top: 60px;
  margin-bottom: 60px;
}
.brand-text-box {
  padding-left: 50px;
}

.red-text-heading-up {
  font-size: 1.8rem;
  color: #f13030;
  line-height: normal;
  margin-bottom: 49px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}
.subheading-text-p {
  color: #000000;
  font-size: 3rem;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  margin-bottom: 16px;
}
.subpara-text {
  color: #1f1f1f;
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
  line-height: normal;
  font-weight: 300;
}

.our-services-section {
  background-color: #f2f2f2;
  padding: 50px 0 200px;
}
.our-services-section-bottom {
  padding: 50px 0 0;
}

.our-services-section .subpara-text,
.our-services-section-bottom .subpara-text {
  max-width: 60%;
  margin: 0 auto 60px;
}

.our-services-section-bottom .subpara-text {
  margin-bottom: 0;
}
.card-box-services {
  position: relative;
  margin: 35px 0;
}
.white-bg-box {
  background-color: #ffffff;
  padding-bottom: 70px;
}
.white-bg-box img {
  max-width: 452px;
}

.card-services-content .product-name-text {
  font-size: 2.5rem;
  color: #000000;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  margin-bottom: 44px;
}
.card-services-content .product-para-text {
  font-size: 1.3rem;
  font-weight: 300;
  color: #1f1f1f;
  margin: 0;
}
#main .common-buttons-medna,
#header .common-buttons-medna,
#footer .common-buttons-medna {
  font-family: "Inter", sans-serif;
  color: #ffffff;
  padding: 13px 16px 12px;
  background: #f13030;
  border: 1px solid #f13030;
}
.nav-menu a.common-buttons-medna-contact-us-header {
  border-radius: 35px;
  color: #ffffff;
  padding: 14px 37px;
}
button:focus {
  outline: none;
}
#header .common-buttons-medna:hover,
#header .common-buttons-medna:focus,
#main .common-buttons-medna:hover,
#main .common-buttons-medna:focus,
#footer .common-buttons-medna:hover,
#footer .common-buttons-medna:focus {
  background: #ffffff;
  color: #f13030;
  cursor: pointer;
  border-color: #f13030;
  box-shadow: none;
}
.our-services-section button.common-buttons-medna {
  margin-top: 45px;
  margin-bottom: 0px;
}
.right-image .image-section {
  order: 2;
}
.our-services-section-bottom .card-box {
  background: #f5f5f5;
  padding: 70px 30px 30px;
}
.our-services-section-bottom .card-box.card-box-red {
  background: #df3940;
}
.our-services-section-bottom .card-box .text-value {
  font-size: 1.5rem;
  color: #000000;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  text-align: center;
  margin: 20px 0 0;
}
.our-services-section-bottom .card-box img {
  margin: auto;
}
.our-services-section-bottom .card-box .testing-text {
  font-size: 1rem;
  color: #000000;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  text-align: center;
}
.our-services-section-bottom .card-box.card-box-red .text-value,
.our-services-section-bottom .card-box.card-box-red .testing-text {
  color: #ffffff;
}
.icon-box {
  width: 150px;
  height: 150px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  display: flex;
  margin: auto;
}
.circle-box {
  width: 120px;
  height: 120px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
section.our-services-section-bottom div.owl-carousel .owl-item img {
  height: 80px;
  width: auto;
}
div.testimonials-carousel .owl-nav.disabled,
.recent-article-carousel .owl-nav.disabled {
  display: block;
}
.our-services-section-bottom,
.recent-articles {
  position: relative;
}

div.testimonials-carousel .owl-nav button i,
div.testimonials-carousel .owl-nav.disabled button i,
.recent-article-carousel button i {
  color: #ffffff;
}
div.testimonials-carousel .owl-nav button.owl-prev,
div.testimonials-carousel .owl-nav.disabled button.owl-prev,
.recent-article-carousel button.owl-prev {
  left: -77px;
}
div.testimonials-carousel .owl-nav button.owl-prev i,
div.testimonials-carousel .owl-nav.disabled button.owl-prev i,
.recent-article-carousel button.owl-prev i {
  transform: rotate(90deg);
}
div.testimonials-carousel .owl-nav button.owl-next,
div.testimonials-carousel .owl-nav.disabled button.owl-next,
.recent-article-carousel button.owl-next {
  right: -77px;
}
div.testimonials-carousel .owl-nav button.owl-next i,
div.testimonials-carousel .owl-nav.disabled button.owl-next i,
.recent-article-carousel button.owl-next i {
  transform: rotate(-90deg);
}
.recent-article-carousel .card-box {
  box-shadow: 0 0px 6px 0 rgb(0 0 0 / 28%);
  margin: 20px 15px;
  padding: 13px 15px;
}
.recent-article-carousel .card-box .text-value {
  color: rgba(87, 85, 85, 0.3);
}
.recent-article-carousel .card-box .text-value i {
  margin-left: 10px;
}
.recent-article-carousel .card-box .text-value {
  padding: 20px 17px 0;
}
.recent-article-carousel .blog-text-heading {
  color: rgba(87, 85, 85, 1);
  font-size: 1.7rem;
  font-weight: 600;
  padding: 15px 20px 10px;
  font-family: "Inter", sans-serif;
  margin: 0;
}
.recent-article-carousel .blog-text-description {
  font-size: 1rem;
  color: rgba(87, 85, 85, 1);
  font-weight: 300;
  padding: 0 20px;
  font-family: "Inter", sans-serif;
  margin: 0;
}
.recent-article-carousel a.read-more-buton {
  font-size: 1.2rem;
  color: rgba(87, 85, 85, 1);
  font-weight: 600;
  font-family: "Inter", sans-serif;
  margin: 20px 20px;
  display: inline-block;
}
.recent-article-carousel .card-box .text-value span {
  min-width: 62px;
  display: inline-block;
  margin-left: 10px;
}

.testimonial-section-menda .container-fluid {
  padding: 0;
}
.testimonial-section-menda .flex-divss div.profile-image {
  max-width: 40%;
}
.testimonial-section-menda .flex-divss div.profile-image img {
  max-width: 100%;
}
.rating-box-test-main {
  background: #2e2e2e;
}
.rating-box-test .d-flex {
  width: 100%;
}
.div-same {
  flex: 1;
}
.rating-box-test-main h4.red-text-heading-up,
.rating-box-test-main h2 {
  background: #ffffff;
  padding: 0 30px;
}
.rating-box-test-main h2 {
  padding-bottom: 42px;
}
.rating-box-test-main h4.red-text-heading-up {
  padding-bottom: 49px;
  margin-bottom: 0px;
}
.rating-box-test {
  padding: 40px 40px 0;
  color: #ffffff;
}
.rating-box-test .profile-information {
  padding-left: 42px;
}
.rating-box-test .user-comment {
  padding-top: 50px;
}
.recent-articles {
  padding: 50px 0;
}

.recent-article-carousel {
  padding-top: 50px;
  padding-bottom: 40px;
}
.testimonial-section-menda {
  padding-bottom: 70px;
}
.mobile-nav-toggle {
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.banner-section-home-contact {
  min-height: auto;
  padding-bottom: 200px;
}
.banner-section-home-contact-orig {
  background-image: none;
  padding-bottom: 50px;
}
.contact-detail {
  padding-top: 100px;
}
.card-boxes-contact {
  background-color: #ffffff;
  width: calc(33.33% - 20px);
  flex: 0 0 calc(33.33% - 20px);
  text-align: center;
  box-shadow: 0 2px 9px 0 rgb(0 0 0 / 10%);
  padding: 100px 30px;
  margin-bottom: 53px;
}
.card-boxes-contact i {
  color: #f13030;
  font-size: 40px;
}
.card-boxes-contact a {
  display: inline-block;
  font-size: 1.5rem;
  color: #000000;
  margin-top: 40px;
}
.contact-form-div {
  background: rgba(231, 231, 231, 1);
  padding: 55px 100px;
}
.contact-form-div button {
  margin-top: 47px;
  margin-bottom: 10px;
}
.map-value {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 80px;
}

.form-select.input-btn-value {
  max-width: 100%;
}

/* New Contact Us */
.common-links-contact-us li {
  list-style-type: none;
  padding: 15px 0;
}
.common-links-contact-us li a {
  display: flex;
  align-items: center;
}
.common-links-contact-us li a img {
  max-width: 50px;
}
.common-links-contact-us li span {
  color: #0d0d0d;
  font-size: 2rem;
  padding-left: 55px;
  max-width: 400px;
}
.contact-form-div-new button {
  max-width: max-content;
  border-radius: 0;
  margin-top: 10px;
}
.red-text-heading-up-contact {
  font-size: 2.3rem;
}
/* End */
footer a:hover {
  color: #f13030;
}
input.error,
textarea.error,
select.error {
  border: 1px solid red;
}
.search-page li {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
  padding-bottom: 5px;
}
.mobile-show {
  display: none;
}
.navbar-mobile .mobile-menu {
  display: flex !important;
}
.our-services-section1 {
  background-color: #f2f2f2;
  padding: 50px;
}
.about-us-section1 {
  background-color: #ffffff;
}
.p {
  width:95%;
}
.pad {
  padding: 50px;
}
.search-job-input {
  /* height: 40px;
  border: 2px solid; */
  padding: 10px;
  width: 100%;
  line-height: initial;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}
.mb20 {
  margin-bottom: 20px;
}
.job-table{
  display: block;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
}
.job-table > tbody tr:nth-child(even) {
  background-color: #e9ebf5;
}
.job-table > tbody tr:nth-child(odd) {
  background-color: #cfd5ea;
}
.job-table>thead:first-child>tr:first-child>th {
  background-color: #005aab;
  color: #ffffff;
  padding: 10px;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  line-height: normal;
  font-weight: 400;
}
.job-table>tbody>tr>td {
  border: 1px solid #e3e3e3;
  padding: 10px;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  line-height: normal;
  font-weight: 300;
}
.success-text {
  font-size: 22px;
  text-align: center;
}
.loading {
  text-align: center;
}
.error{
  color: red;
  padding: 3px;
}
.paginationView {
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  text-align: center;
  inline-size: fit-content;
}
.paginationView label {
  color:#adadad;
}

.jobsearchForm button.search-form{
  font-family: "Inter", sans-serif;
  color: #ffffff;
  padding: 8px 16px;
  background: #f13030;
  border: 1px solid #f13030;
}

#main .common-back-buttons-medna,
#header .common-back-buttons-medna,
#footer .common-back-buttons-medna {
  font-family: "Inter", sans-serif;
  color: #ffffff;
  padding: 13px 16px 12px;
  background: #4e4e4e;
  border: 1px solid #0f0f0f;
}
#header .common-back-buttons-medna:hover,
#header .common-back-buttons-medna:focus,
#main .common-back-buttons-medna:hover,
#main .common-back-buttons-medna:focus,
#footer .common-back-buttons-medna:hover,
#footer .common-back-buttons-medna:focus {
  background: #ffffff;
  color: #4e4e4e;
  cursor: pointer;
  border-color: #0f0f0f;
  box-shadow: none;
}
.job-details-view label {
  padding-top: 0px;
}
.job-table>thead>tr>th {
  border-right: 1px solid #ededed;
}
.job-subpara-text {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  line-height: normal;
  font-weight: 300;
}
.job-subpara-title {
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
  line-height: normal;
  font-weight: 500;
}
.pagination {
    justify-content: center !important;
}
.pagination-label {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}
#inputFilesLabel{
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  width: 200px;
  margin-left: 10px;
  background-color: #ededed;
}
#inputFilesLabel:hover{
  background-color: #ffffff;
}
#inputFiles {
  display: none;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 240px;
  top: 0;
  height: 50%;
  width: 60%;
  position: absolute;
}